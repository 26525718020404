'use client'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { TransactionAPI } from '@/apis/transactionAPI'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import Spinner from '@/components/common/spinner'
import NotFoundError from '@/components/common/notFoundError'

import {
  PaymentActionTypes,
  usePaymentContext,
  useUserContext
} from '@/providers'
import { useGalleryContext } from '@/providers/gallery'
import { usePackageContext } from '@/providers/package'
import { inAppRouteList } from '@/constants'
import { ImageAPI } from '@/apis/imageApis'

const PreviewPage = () => {
  const navigate = useNavigate()

  const { context: userContext } = useUserContext()
  const { context: paymentContext, dispatch: paymentDispatch } = usePaymentContext()
  const { context: galleryContext } = useGalleryContext()
  const { context: packageContext } = usePackageContext()

  const [img, setImg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [luckyNumber, setLuckyNumber] = useState('')

  if (!userContext.previewImage) return <Navigate to={inAppRouteList.ProductList} />

  // When selectedProduct is undefined, this page should return error
  const selectedProduct = galleryContext.find(g => g._id == userContext.galleryId)
  if (!(selectedProduct && selectedProduct.title)) {
    return <NotFoundError msg="Gallery" cta={true} />
  }

  // When packageItem is undefined, this page should return error
  const packageItem = packageContext.find(pkg => pkg.galleryId == userContext.galleryId)
  if (!(packageItem && packageItem.imgOriginal)) {
    return <NotFoundError msg="Package" cta={true} />
  }

  // When selectedPackageItem is undefined, this page should return error
  const selectedPackageItem = packageItem.items[Number(userContext.itemId)]
  if (!(selectedPackageItem && selectedPackageItem.imageSuffix)) {
    return <NotFoundError msg="Package" cta={true} />
  }

  const goToPayment = () => {    
    const data = {
      user_id: userContext.uid,
      selected_configuration: {
        gallery: {
          _id: selectedProduct._id,
          title: selectedProduct.title,
          sub_title: selectedProduct.subTitle,
          gallery_name: selectedProduct.galleryName,
          status: selectedProduct.status,
          status_expire: selectedProduct.statusExpire,
          is_enabled: selectedProduct.isEnabled,
          order: selectedProduct.order,
          package: [
            {
              gallery_id: packageItem.galleryId,
              img_original: packageItem.imgOriginal,
              limit: packageItem.limit,
              title: packageItem.title,
              type: packageItem.type,
              items: [
                {
                  name: selectedPackageItem.name,
                  icon: selectedPackageItem.icon,
                  cost: selectedPackageItem.cost,
                  image_suffix: selectedPackageItem.imageSuffix
                }
              ]
            }
          ]
        },
        overlayText: `${userContext.firstName} ${userContext.lastName}`,
        luckyNumber,
      }
    }

    TransactionAPI.createTransaction(data).then(responseData => {
      const updatedValues = {
        ...paymentContext,
        ...{
          id: responseData
        }
      }
      paymentDispatch({
        type: PaymentActionTypes.CreatePaymentID,
        payload: { ...updatedValues }
      })

      navigate('/payment')
    })
  }

  const getImage = async () => {
    const { data } = await ImageAPI.getOverlayText(
      userContext.galleryName, 
      userContext.imageDirectory, 
      userContext.uid, 
      `${userContext.firstName} ${userContext.lastName}`,
      luckyNumber
    )

    setImg(data)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)

    if (selectedProduct.luckyNumber){
      const number = selectedProduct.luckyNumber.find(ln => ln.key === userContext.zodiac)?.value || ''
      setLuckyNumber(number)
    }
  }, [])

  // Hook to guarantee that luckyNumber updated success
  useEffect(() => {
    if (luckyNumber) {
      getImage()
    }
  }, [luckyNumber])

  if (isLoading) return <Spinner />
  
  return (
    <>
      <div className="flex my-4 items-center justify-center h-12">
        <p className="font-medium text-xl text-center">ภาพตัวอย่างหลังสลักชื่อ</p>
      </div>

      <Card className="w-[350px] bg-neutral-100 py-6 shadow-xl mb-[12vh]">
        <CardContent className="grid">
          <div className="text-sm text-gray-600 text-center mb-4">กดภาพเพื่อดูขนาดใหญ่</div>

          <div className="flex-1 flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="flex-1 relative w-full">
                <Dialog>
                  <DialogTrigger asChild>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <img
                        src={`data:image/png;base64,${img}`}
                        alt="wallpaper"
                        crossOrigin="anonymous"
                        className="pointer-events-none max-h-[300px]"
                      />
                    </div>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-full h-full">
                    <div className="flex-1 flex flex-col gap-2">
                      <div className="flex gap-2">
                        <div className="flex-1 relative">
                          <div className="flex flex-col h-screen justify-center items-center">
                            <img
                              src={`data:image/png;base64,${img}`}
                              alt="wallpaper"
                              crossOrigin="anonymous"
                              className="pointer-events-none h-[90vh]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
          
          <div className="text-sm text-gray-400 text-center mt-2">โปรดตรวจสอบความถูกต้อง<br/>ก่อนกด "ชำระเงิน"</div>
          <div className="text-sm text-gray-900 text-center mt-2">ภาพพื้นหลัง 1 ภาพ {selectedPackageItem.cost} บาท</div>
      
          <div className="text-sm font-medium text-dark-red-700 text-center mt-2">
            ภาพที่ได้รับอาจถูกตัดบางส่วน ตามการแสดงผลภาพพื้นหลังของหน้าจอ
          </div>
        </CardContent>
      </Card>

      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
            id={`btn-preview-${userContext.galleryName}`}
            onClick={goToPayment}
          >
            ต่อไป
          </Button>
        </div>
      </div>
    </>
  )
}

export default PreviewPage
