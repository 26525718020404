import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  PropsWithChildren
} from 'react'

import { PaymentActionTypes, PaymentActions } from './action'
import { PaymentContext } from '@/providers/payment/types'

const defaultPaymentContext: PaymentContext = {
  id: '',
  type: 'credit',
  amount: 0.00,
  referenceNo: '',
  status: '',
  resultCode: '',
  secret_key: '',
  public_key: '',
  customer_key: '',
}

const PaymentContext = createContext<{
  context: PaymentContext
  dispatch: Dispatch<PaymentActions>
} | null>(null)

const reducer = (
  context: PaymentContext,
  { type, payload }: PaymentActions
) => {
  switch (type) {
    case PaymentActionTypes.SetGBPayKeys:
      return { ...context, ...payload }
    case PaymentActionTypes.CreatePaymentID:
      return { ...context, ...payload }
    case PaymentActionTypes.SubmitPayment:
      return { ...context, ...payload }
    default:
      throw new Error('invalid action')
  }
}

export const usePaymentContext = () => {
  const ctx = useContext(PaymentContext)

  if (!ctx)
    throw new Error('usePaymentContext can only be used in PaymentContext')

  return ctx
}

export const PaymentProvider = ({ children }: PropsWithChildren) => {
  const [context, dispatch] = useReducer(reducer, defaultPaymentContext)

  return (
    <PaymentContext.Provider value={{ context, dispatch }}>
      {children}
    </PaymentContext.Provider>
  )
}
